/**
 * A HeroImage is a simple component that displays a hero image. An image file
 * with the name `hero` is expected to be found in the `images` directory.
 */

import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

const HeroImage = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(
        name: { eq: "hero" }
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      ) {
        id
        name
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <Img
        fluid={data.heroImage.childImageSharp.fluid}
        style={style}
        fadeIn={false}
        loading="eager"
      />
      <Container className="hero-image-overlay">
        <Row>
          <Col xs={12}>
            <p className="hero-caption">
              The final Environmental Impact statement has been released.
            </p>
          </Col>
          <Col xs={12} className="hero-text">
            <p>
              You can read the final EIS{" "}
              <a
                href="/download-the-eis"
                style={{ color: "white", textDecoration: "underline" }}
              >
                here
              </a>
              .
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

HeroImage.propTypes = {
  style: PropTypes.object,
};

export default HeroImage;
